<template>
	<div class="main-content">
		<ktv-breadcrumb title="Dashboard" page="Dashboard" folder="Loan" />
		<b-tabs active-nav-item-class="nav nav-tabs">
			<b-tab title="Dashboard" :active="activeTabDashboard">
				<b-row>
					<b-col lg="3" md="6" sm="12">
						<b-card class="card-icon-bg card-icon-bg-primary o-hidden mb-30 text-center">
							<i class="i-Refresh" />
							<div class="content">
								<p class="text-muted mt-2 mb-0">Waiting to Review</p>
								<p class="text-primary text-24 line-height-1 mb-2">62</p>
							</div>
						</b-card>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-card class="card-icon-bg card-icon-bg-primary o-hidden mb-30 text-center">
							<i class="i-Remove" />
							<div class="content">
								<p class="text-muted text-left mt-2 mb-0">Application Missing Docs</p>
								<p class="text-primary text-24 line-height-1 mb-2">10</p>
							</div>
						</b-card>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<b-card class="card-icon-bg card-icon-bg-primary o-hidden mb-30 text-center">
							<i class="i-Yes" />
							<div class="content">
								<p class="text-muted text-left mt-2 mb-0">Application Approved</p>
								<p class="text-primary text-24 line-height-1 mb-2">10</p>
							</div>
						</b-card>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-card class="mb-30">
							<div class="text-right">
								<b-form-select v-model="filterchart" :options="options.filterchart" style="width: 30%" />
							</div>
							<h4 class="card-title m-0">Application Submitted</h4>
							<div class="chart-wrapper" style="height: 300px; width: 100%">
								<v-chart id="echartbar" :options="echartBar" :autoresize="true" />
							</div>
						</b-card>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-card class="mb-30">
							<b-list-group>
								<b-list-group-item>
									<div class="text-18">
										<b>Application with Missing Docs</b>
									</div>
								</b-list-group-item>
								<b-list-group-item>
									<b-row align-v="center">
										<b-col>
											#LN0001 <br />
											<b>Dadang Supratman</b>
										</b-col>
										<b-col class="text-right">
											Submitted on <br />
											<b>27 November 2021</b>
										</b-col>
									</b-row>
								</b-list-group-item>
								<b-list-group-item>
									<b-row align-v="center">
										<b-col>
											#LN0005 <br />
											<b>Adin Safrudin</b>
										</b-col>
										<b-col class="text-right">
											Submitted on <br />
											<b>21 Agustius 2021</b>
										</b-col>
									</b-row>
								</b-list-group-item>
								<b-list-group-item>
									<b-row align-v="center">
										<b-col>
											#LN0019 <br />
											<b>Melina</b>
										</b-col>
										<b-col class="text-right">
											Submitted on <br />
											<b>1 Desember 2021</b>
										</b-col>
									</b-row>
								</b-list-group-item>
							</b-list-group>
						</b-card>
					</b-col>
				</b-row>
			</b-tab>
			<b-tab title="Report" :active="activeTabReport">
				<b-row>
					<b-col lg="3" md="6" sm="12" class="mb-2 mt-3">
						<b-card class="card-icon-bg card-icon-bg-primary" no-body>
							<div class="boxreportcard">
								Application Count <br />
								<b class="text-30">200</b>
								<p><b-badge href="#" variant="success mr-2"> +15% </b-badge>than last month</p>
							</div>
						</b-card>
					</b-col>
					<b-col lg="3" md="6" sm="12" class="mb-2 mt-3">
						<b-card class="card-icon-bg card-icon-bg-primary" no-body>
							<div class="boxreportcard">
								Application on Review Count <br />
								<b class="text-30">62</b>
								<p><b-badge href="#" variant="danger mr-2"> -5% </b-badge>than last month</p>
							</div>
						</b-card>
					</b-col>
					<b-col lg="3" md="6" sm="12" class="mb-2 mt-3">
						<b-card class="card-icon-bg card-icon-bg-primary" no-body>
							<div class="boxreportcard">
								Approved Application Count <br />
								<b class="text-30">135</b>
								<p><b-badge href="#" variant="success mr-2"> +25% </b-badge>than last month</p>
							</div>
						</b-card>
					</b-col>
					<b-col lg="3" md="6" sm="12" class="mb-2 mt-3">
						<b-card class="card-icon-bg card-icon-bg-primary" no-body>
							<div class="boxreportcard">
								Disburse Loan Count <br />
								<b class="text-30">120</b>
								<p><b-badge href="#" variant="success mr-2"> +17% </b-badge>than last month</p>
							</div>
						</b-card>
					</b-col>
					<b-col lg="3" md="6" sm="12" class="mb-2 mt-3">
						<b-card class="card-icon-bg card-icon-bg-primary" no-body>
							<div class="boxreportcard">
								Value of Approved Loans <br />
								<b class="text-30">8800</b>
								<p><b-badge href="#" variant="success mr-2"> +74% </b-badge>than last month</p>
							</div>
						</b-card>
					</b-col>
					<b-col lg="3" md="6" sm="12" class="mb-2 mt-3">
						<b-card class="card-icon-bg card-icon-bg-primary" no-body>
							<div class="boxreportcard">
								Value of Disburse Loans <br />
								<b class="text-30">2000</b>
								<p><b-badge href="#" variant="danger mr-2"> -4% </b-badge>than last month</p>
							</div>
						</b-card>
					</b-col>
					<b-col lg="3" md="6" sm="12" class="mb-2 mt-3">
						<b-card class="card-icon-bg card-icon-bg-primary" no-body>
							<div class="boxreportcard">
								Turn Around Time <br />
								<b class="text-30">10</b>
								<p><b-badge href="#" variant="success mr-2"> +18% </b-badge>than last month</p>
							</div>
						</b-card>
					</b-col>
				</b-row>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			title: "Dashboard",
		},
		data() {
			return {
				filterchart: "1",
				activeTabDashboard: true,
				activeTabReport: false,
				options: {
					filterchart: [
						{
							text: "Last 7 days",
							value: "1",
						},
					],
				},
				echartBar: {
					legend: {
						borderRadius: 0,
						orient: "horizontal",
						x: "right",
						data: ["A", "B"],
					},
					grid: {
						left: "8px",
						right: "8px",
						bottom: "0",
						containLabel: true,
					},
					tooltip: {
						show: true,
						backgroundColor: "rgba(0, 0, 0, .8)",
					},
					xAxis: [
						{
							type: "category",
							data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
							axisTick: {
								alignWithLabel: true,
							},
							splitLine: {
								show: false,
							},
							axisLabel: {
								color: this.dark_heading,
							},
							axisLine: {
								show: true,
								color: this.dark_heading,
								lineStyle: {
									color: this.dark_heading,
								},
							},
						},
					],
					yAxis: [
						{
							type: "value",
							axisLabel: {
								color: this.dark_heading,
								formatter: "${value}",
							},
							axisLine: {
								show: false,
								color: this.dark_heading,
								lineStyle: {
									color: this.dark_heading,
								},
							},
							min: 0,
							max: 100000,
							interval: 25000,
							splitLine: {
								show: true,
								interval: "auto",
							},
						},
					],
					series: [
						{
							name: "A",
							data: [35000, 69000, 22500, 60000, 50000, 50000, 30000, 80000, 70000, 60000, 20000, 30005],
							label: { show: false, color: "#0168c1" },
							type: "bar",
							barGap: 0,
							color: "#aff0da",
							smooth: true,
							itemStyle: {
								emphasis: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowOffsetY: -2,
									shadowColor: "rgba(0, 0, 0, 0.3)",
								},
							},
						},
						{
							name: "B",
							data: [45000, 82000, 35000, 93000, 71000, 89000, 49000, 91000, 80200, 86000, 35000, 40050],
							label: { show: false, color: "#54A7DC" },
							type: "bar",
							color: "#54A7DC",
							smooth: true,
							itemStyle: {
								emphasis: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowOffsetY: -2,
									shadowColor: "rgba(0, 0, 0, 0.3)",
								},
							},
						},
					],
				},
			}
		},
	}
</script>

<style>
	.card-icon-bg .card-body .content {
		max-width: 100% !important;
	}
	.boxreportcard {
		padding: 10px 20px;
	}
</style>
